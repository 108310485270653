import './App.css';
import { LogoCabanaNaRosaCue, LogoElCapon, LogoGrupoSerNegro, LogoSerfin, LogoServiajeros, LogoServicom, LogoServitravel } from 'assets/logos';
import { Fondo } from 'assets/images';

function App() {
    return (
        <div className="App background-cover" style={{ backgroundImage: 'url("' + Fondo + '")' }}>
            <div className="w3-block w3-center grupo-ser-container w3-animate-top">
                <img id="logo-grupo-ser" className="w3-margin-bottom" src={LogoGrupoSerNegro} alt="logo"></img>
                {/* <div className="animate-flicker"><h2 className="w3-text-gray">Página en construcción</h2></div> */}<br/><br/>
                <span class="loader"></span>
            </div>
            <div className="w3-content">
                <div className="w3-row">
                    <div className="w3-col logo-empresa-container">
                        <div className="container-relative">
                            <div>
                                <div className="w3-display-container">
                                    <div className="w3-display-left w3-block w3-center bs-bbox">
                                        <a href="https://www.serfin.com.py" target="_blank" rel="noreferrer">
                                            <img className="logo-empresa w3-animate-zoom" src={LogoSerfin} alt="logo"></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-col logo-empresa-container">
                        <div className="container-relative">
                            <div>
                                <div className="w3-display-container">
                                    <div className="w3-display-left w3-block w3-center bs-bbox">
                                        <a href="http://www.servitravel.com.py" target="_blank" rel="noreferrer">
                                            <img className="logo-empresa w3-animate-zoom" src={LogoServitravel} alt="logo"></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-col logo-empresa-container">
                        <div className="container-relative">
                            <div>
                                <div className="w3-display-container">
                                    <div className="w3-display-left w3-block w3-center bs-bbox">
                                        <a href="https://serviajeros.com.py/" target="_blank" rel="noreferrer">
                                            <img className="logo-empresa w3-animate-zoom" src={LogoServiajeros} alt="logo"></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-col logo-empresa-container">
                        <div className="container-relative">
                            <div>
                                <div className="w3-display-container">
                                    <div className="w3-display-left w3-block w3-center bs-bbox">
                                        <img className="logo-empresa w3-animate-zoom" src={LogoServicom} alt="logo"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-col logo-empresa-container">
                        <div className="container-relative">
                            <div>
                                <div className="w3-display-container">
                                    <div className="w3-display-left w3-block w3-center bs-bbox">
                                        <a href="https://www.instagram.com/narosacue/" target="_blank" rel="noreferrer">
                                            <img className="logo-empresa logo-empresa-rosa-cue w3-animate-zoom" src={LogoCabanaNaRosaCue} alt="logo"></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-col logo-empresa-container">
                        <div className="container-relative">
                            <div>
                                <div className="w3-display-container">
                                    <div className="w3-display-left w3-block w3-center bs-bbox">
                                        <a href="https://www.instagram.com/elcaponpy_cordero/" target="_blank" rel="noreferrer">
                                            <img className="logo-empresa w3-animate-zoom" src={LogoElCapon} alt="logo"></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;